:root {
    --primary: #142b6f;
}
.stepProgressPar[value]::-webkit-progress-bar {
    background-color:  rgb(229, 231, 235);
    height: 5px;
    border-radius: 14px;
    width: 100%;
}
.stepProgressPar[value]::-webkit-progress-value {
    background-color: var(--primary);
    border-radius: 14px;
}
html, body {
    height: 100%;
}

#root {
    height: 100%;
}